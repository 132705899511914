<template>
<div id="banner">
 <div id="copyright">
   © Odi  Tips

 </div>
</div>
</template>

<script>
export default {
  name: "BottomBanner"
}
</script>

<style scoped>
#banner{
  background-color:#404040;
  padding-top: 10px;
  padding-bottom: 10px;
  bottom: 0;
  right: 0;
  width: 100%;
}
#copyright{
  display: flex;
  justify-content: center;
  color: white;
}

</style>